import { render, staticRenderFns } from "./new_online_time.vue?vue&type=template&id=ba15c354&scoped=true"
import script from "./new_online_time.vue?vue&type=script&lang=ts"
export * from "./new_online_time.vue?vue&type=script&lang=ts"
import style0 from "./new_online_time.vue?vue&type=style&index=0&id=ba15c354&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba15c354",
  null
  
)

export default component.exports